import {mapActions, mapGetters} from "vuex";

export default {
  name: "media",
  data(){
    return{

    }
  },
  created() {
    this.fetchMediaPage()
  },
  computed:{
    ...mapGetters({
      mediaPage:'pages/media'
    })
  },
  methods:{
    ...mapActions({
      fetchMediaPage: 'pages/GET_MEDIA',
    })
  }

}